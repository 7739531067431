import { Link, navigate } from 'gatsby';
import { Box, Row, Typography as T, Button } from '@fuji-ui/core';
import { Wrapper, Nav } from './layout';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const Header = ({ siteLogo }) => (
  <Box as="header" py="l">
    <Wrapper>
      <Row alignItems="center" justifyContent="space-between">
        <Box>
          <Link to="/" className="logo">
            {siteLogo}
          </Link>
        </Box>
        <Nav as="nav">
          <T.H5>
            <Link to="/pricing">Pricing</Link>
          </T.H5>
          <T.H5>
            <a href="https://talkbox.app">About Talkbox</a>
          </T.H5>
          <Button onClick={() => navigate('/request')}>Get it</Button>
        </Nav>
      </Row>
    </Wrapper>
  </Box>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
