import { Box, Typography as T } from '@fuji-ui/core';
import { Wrapper, Nav } from './layout';
import React from 'react';

const Footer = ({ siteLogo }) => (
  <Box as="footer" pt="200px" pb="xl">
    <Wrapper>
      <Nav>
        <T.H6>
          <a href="https://teamworkapp.co/terms">Terms</a>
        </T.H6>
        <T.H6>
          <a to="https://teamworkapp.co/privacy">Privacy</a>
        </T.H6>
        <T.H6>
          <a href="https://talkbox.app">Talkbox</a>
        </T.H6>
        <T.H6>
          <a href="https://teamworkapp.co">Teamwork App</a>
        </T.H6>
      </Nav>
      <T.H6>❤️ crafted by Talkbox</T.H6>
      <T.Span fontSize="xs">COPYRIGHT © 2020 TALKBOX LTD.</T.Span>
    </Wrapper>
  </Box>
);

export default Footer;
