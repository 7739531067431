import React from 'react';
import { Box, Row } from '@fuji-ui/core';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Layout = (props) => <Box {...props} />;

export const Wrapper = (props) => (
  <Box
    m="auto"
    maxWidth={['100%', '100%', '1280px', '1440px']}
    px={['m', 'm', 'l', 'xl']}
    {...props}
  />
);

export const Nav = styled(Row)`
  align-items: center;
  a {
    color: ${themeGet('colors.onBackground')};
    border-bottom: 2px solid ${themeGet('colors.onBackground')};
  }
  a:hover {
    opacity: 0.8;
    color: ${themeGet('colors.onBackground')};
  }
  > * {
    margin: 0;
    margin-left: 1rem;
  }
`;
