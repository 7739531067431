/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { ThemeProvider } from '@fuji-ui/theme';
import theme from './src/style/theme';
import { Button, Typography as T, Box, Row, Column } from '@fuji-ui/core';
import { MDXProvider } from '@mdx-js/react';
import { Layout } from './src/components/layout';
import Header from './src/components/header';
import Footer from './src/components/footer';

import siteLogoImg from './src/images/logo_enlist.svg';
import './src/style/theme.css';
import './src/style/global.css';

const FujiComps = {
  h1: T.H1,
  h2: T.H2,
  h3: T.H3,
  h4: T.H4,
  H5: T.H5,
  H6: T.H6,
  p: T.P,
  Button,
  Box,
  Row,
  Column,
};

const SiteLogo = () => (
  <Box as="img" src={siteLogoImg} width={[100, null, 140, null]} />
);

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <MDXProvider components={FujiComps}>{element}</MDXProvider>
  </ThemeProvider>
);

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it

  return (
    <Layout {...props}>
      <Header siteLogo={<SiteLogo />} />
      {element}
      <Footer />
    </Layout>
  );
};
